import brand from '@brand'

const refFncm = 'fncm'
const brandMapping = {
  fonic: 'fnc',
  'fonic-mobile': 'fncm'
}

const loadGtm = () => {
  const isProd =
    window.location.hostname === 'www.fonic.de' ||
    window.location.hostname === 'www.fonic-mobile.de'

  const config = isProd
    ? brand.googleTagManager.prod
    : brand.googleTagManager.dev

  const firstScriptTag = document.getElementsByTagName('script')[0]
  const script = document.createElement('script')
  const dataLayerName = config.dataLayerName

  window[dataLayerName] = window[dataLayerName] || []

  window[dataLayerName].push({
    event: 'gtm.js',
    'gtm.start': new Date().getTime()
  })

  script.async = true
  script.type = 'text/javascript'
  script.setAttribute('data-usercentrics', 'Google Tag Manager')
  if (isProd) {
    script.src = `https://www.googletagmanager.com/gtm.js?id=${config.id}&l=${dataLayerName}`
  } else {
    script.src = `https://www.googletagmanager.com/gtm.js?id=${config.id}&l=${dataLayerName}&gtm_auth=${config.auth}&gtm_preview=${config.env}&gtm_cookies_win=x`
  }

  firstScriptTag.parentNode.insertBefore(script, firstScriptTag)
}

const trackPage = async (app, to, store) => {
  const isAuthenticated = store.getters['authentication/isLoggedIn']
  const hasCustomerData = store.getters['customer/hasCustomerData']
  const refPortal = store.getters['customer/getRefPortal']
  const queryParams = Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  )
  let customer

  if (refFncm in queryParams) {
    store.commit('customer/updateRefPortal', refFncm)
  } else if (!refPortal) {
    store.commit('customer/updateRefPortal', brandMapping[brand.code])
  }

  if (isAuthenticated && !hasCustomerData) {
    await store.dispatch('customer/fetchCustomerData')
  }

  if (isAuthenticated) {
    customer = store.getters['customer/getCustomerData']
  } else {
    customer = {
      login: store.getters['authentication/getTrackingLoginStatus']
    }
  }

  Object.assign(customer, {
    ref_portal: store.getters['customer/getRefPortal']
  })

  const type = to.meta && to.meta.type ? to.meta.type : 'selfcare'
  const page = {
    event: 'virtual',
    site: {
      brand: brand.code,
      status: '200'
    },
    page: {
      content: to.path,
      template: to.name,
      type
    },
    customer
  }

  app.tracking.getGtm().push(page)
}

export { loadGtm, trackPage }
